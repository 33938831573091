import {ReservationStatus} from '@wix/events-types'
import {createEventHandler} from '@wix/tpa-settings'
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo'
import {
  ExperimentNames,
  GROUPS_APP_ID,
  GROUPS_SECTION_ID,
  PAID_PLANS_APP_ID,
  PAID_PLANS_SECTION_ID,
  isRtlLanguage,
} from '@wix/wix-events-commons-statics'
import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {setInstance, watchInstance} from '../../commons/actions/instance'
import {createUouBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {SLOT_EVENT_DETAILS_CONTENT, SLOT_EVENT_DETAILS_HEADER} from '../../commons/constants/slots'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {SiteSettings} from '../../commons/types/state'
import {isEventsInMembersInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl, isSchedulePageInstalled} from '../../commons/utils/wix-code-api'
import {getExtraEventData} from './Widget/actions/event'
import {getEventsMembersPageInfo} from './Widget/actions/events-members-page'
import {getExportedActions} from './Widget/actions/exported-actions'
import {addLoginListener, fetchCurrentMember} from './Widget/actions/members'
import {openSeatingPlan} from './Widget/actions/modals'
import {addLocationListener, handleInitialNavigation, navigate} from './Widget/actions/navigation'
import {getMemberRsvp} from './Widget/actions/rsvp'
import {updateSettings} from './Widget/actions/settings'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import {DetailsPageProps} from './Widget/components/app/interfaces'
import {
  DetailsPageSettingsEventsKeys,
  DetailsSettingsNavigateActions,
  SettingsEvents,
  WarmupStateKey,
} from './Widget/constants'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {defaultCheckoutState} from './Widget/reducers/checkout'
import {defaultInvoiceState} from './Widget/reducers/invoice'
import {shouldNavigateToHeadlessTYP} from './Widget/selectors/navigation'
import {getDemoEvent} from './Widget/services/demo-event'
import type {Navigation, SelectedTicket, State, StoreExtraArgs} from './Widget/types'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'
import {parseLocation} from './Widget/utils/navigation'
import {getDraftToken} from './Widget/utils/query'

const createDetailsPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )
  let store = null

  return {
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
    updateAppSettings: async (_$w, action) => {
      if (store) {
        setTimeout(() => {
          store.dispatch(updateSettings(action as any))
        }, 100)
      }
    },
    async pageReady() {
      const {
        wixCodeApi,
        appParams: {instance},
      } = controllerParams.controllerConfig
      const {flowAPI} = controllerParams
      const language = getLanguage(wixCodeApi)
      const controller = controllerParams.controllerConfig
      const pageUrl = await getPageUrl(wixCodeApi)

      store = await getStore(controllerParams)
      store.dispatch(setInstance(instance))
      const state = store.getState()

      if (flowAPI.experiments.enabled(ExperimentNames.EventDetailsSlotsViewer)) {
        if (state.event?.id) {
          try {
            setSlotData(controllerParams, state.event.id)
          } catch (e) {
            console.log(e)
            flowAPI.reportError(e)
          }
        }
      }
      store.dispatch(addLoginListener() as any)
      watchInstance(controller, store.dispatch)
      addLocationListener(controllerParams, store)

      const props: DetailsPageProps = {
        state,
        actions: getExportedActions(store),
        isRTL: isRtlLanguage(language),
        pageUrl,
        staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
        // @ts-expect-error
        fitToContentHeight: true,
      }
      controller.setProps(props)
      subscribeToStateChanges(controller, store)
      if (state.membersAreaEnabled) {
        store.dispatch(getEventsMembersPageInfo() as any)
      }

      componentEventHandler.on(
        DetailsPageSettingsEventsKeys.Navigate,
        (navigateAction: DetailsSettingsNavigateActions) => {
          if (navigateAction === DetailsSettingsNavigateActions.details) {
            store.dispatch(navigate(DETAILS_ROUTE.DETAILS) as any)
          }
          if (navigateAction === DetailsSettingsNavigateActions.form) {
            store.dispatch(navigate(DETAILS_ROUTE.FORM) as any)
          }
        },
      )
    },
  }
}

const getStore = async (controllerParams: ControllerParams) => {
  const {
    flowAPI,
    controllerConfig: {wixCodeApi},
  } = controllerParams
  const experiments = flowAPI.experiments.all()
  const serverApi = new Api(controllerParams)
  const ssr = flowAPI.environment.isSSR

  const state: State = wixCodeApi.window.warmupData.get(WarmupStateKey) ?? undefined

  if (ssr || !state) {
    const [initialData, paidPlansEnabled, membersAreaEnabled, groupsInstalled] = await fetchInitialData(
      controllerParams,
      serverApi,
    )

    const newStore = createStore(
      controllerParams,
      {
        ...initialData,
        experiments,
        membersAreaEnabled,
        paidPlansEnabled,
        groups: {installed: groupsInstalled},
      },
      serverApi,
    )

    await newStore.dispatch(setBaseEnvironment() as any)
    await newStore.dispatch(fetchCurrentMember() as any)

    await Promise.all([
      newStore.dispatch(handleInitialNavigation() as any),
      newStore.dispatch(getMemberRsvp() as any),
      ...newStore.dispatch(getExtraEventData() as any),
      wixCodeApi.location.query.chooseSeat || wixCodeApi.location.query.chooseMultipleSeats
        ? newStore.dispatch(openSeatingPlan() as any)
        : null,
    ])
    const data = newStore.getState()
    if (flowAPI.experiments.enabled(ExperimentNames.UseWarmupState) && ssr) {
      wixCodeApi.window.warmupData.set(WarmupStateKey, data)
    }
    return newStore
  } else {
    const oldStore = createStore(controllerParams, state, serverApi)
    if (shouldNavigateToHeadlessTYP(state)) {
      oldStore.dispatch(handleInitialNavigation() as any)
    }
    return oldStore
  }
}

const createStore = (controllerParams: ControllerParams, initialData: any, serverApi: any) => {
  const {controllerConfig: controller} = controllerParams
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = createUouBiMiddlewareWithBiParams(
    {
      wixCodeApi,
      platformAPIs,
      appParams,
      compId,
    },
    eventsUou,
  )

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState},
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      platformAPIs,
      flowAPI: controllerParams.flowAPI,
    },
    middleware: [biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })
}

const getInitialData = async (serverApi: Api, controller: ControllerParams): Promise<Partial<State>> => {
  const {wixCodeApi, config} = controller.controllerConfig
  const flowAPI = controller.flowAPI
  const navigation = parseLocation(wixCodeApi, controller.flowAPI)
  const {slug} = navigation
  const responsive = isResponsiveEditor(config)

  const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)
  const currentUser = wixCodeApi.user.currentUser
  const newClassicDemoEvents = flowAPI.experiments.enabled(ExperimentNames.UpdatedClassicAddPanel)

  const {event, siteSettings, demoEvents, tickets, demoTickets, schedule, dates, upcomingOccurrencesCount} =
    await serverApi.getData({
      slug,
      responsive,
      schedulePageInstalled,
      draftPreviewToken: getDraftToken(wixCodeApi),
      flowAPI,
      newClassicDemoEvents: newClassicDemoEvents ? true : undefined,
    })

  const invoiceState = await getStateFromInvoice({serverApi, event, navigation})

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsive) : event,
    siteSettings: siteSettings as SiteSettings,
    demoEvents,
    tickets,
    demoTickets,
    schedule,
    upcomingOccurrencesCount,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    policies: {
      agreed: false,
      policies: [],
      showAlert: false,
    },
    dates,
    currentUser: {
      id: currentUser.id,
      role: currentUser.role,
      loggedIn: currentUser.loggedIn,
    },
    ...invoiceState,
  }
}

const getStateFromInvoice = async ({
  serverApi,
  event,
  navigation,
}: {
  serverApi: Api
  event: wix.events.Event
  navigation: Navigation
}): Promise<Partial<State>> => {
  if (navigation.route === DETAILS_ROUTE.TICKET_FORM && event?.id) {
    const {
      query: {reservationId},
    } = navigation

    if (reservationId && reservationId !== 'undefined') {
      try {
        const invoice = await serverApi.getInvoice(event.id, reservationId)

        if (invoice.reservationStatus === ReservationStatus.RESERVATION_PENDING) {
          const selectedTickets = invoice.reservations.reduce((acc, item) => {
            const priceOptionIds = item.ticketDetails
              .reduce((accumulator, ticketDetails) => {
                for (let i = 0; i < ticketDetails.capacity; i++) {
                  accumulator.push(ticketDetails.pricingOptionId)
                }
                return accumulator
              }, [])
              .filter(a => a)

            return {
              ...acc,
              [item.ticket.id]: {
                quantity: item.quantity,
                donation: item.ticketDetails[0]?.priceOverride,
                pricingOptionIds: priceOptionIds.length ? priceOptionIds : null,
              } as SelectedTicket,
            }
          }, {} as Record<string, SelectedTicket>)

          const ticketsDetailsValidity = invoice.reservations.reduce(
            (acc, ticketReservation) => [...acc, ...Array(ticketReservation.quantity).fill(false)],
            [],
          )
          return {
            invoice: {
              ...defaultInvoiceState,
              invoice: invoice.invoice,
            },
            selectedTickets,
            checkout: {
              ...defaultCheckoutState,
              ticketsDetailsValidity,
            },
            reservation: {
              data: {
                id: reservationId,
                expires: invoice.expires,
              },
              error: null,
            },
          }
        }

        return {}
      } catch (e) {
        return {}
      }
    }
  }

  return {}
}

const fetchInitialData = async (controller: ControllerParams, serverApi: Api) => {
  const {wixCodeApi} = controller.controllerConfig
  return Promise.all([
    getInitialData(serverApi, controller),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
    isEventsInMembersInstalled(wixCodeApi),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: GROUPS_APP_ID,
      sectionId: GROUPS_SECTION_ID,
    }),
  ])
}

const setSlotData = (controllerParams: ControllerParams, eventId: string) => {
  const slotAPIFactory = createSlotVeloAPIFactory(controllerParams.controllerConfig)
  for (const slotId of [SLOT_EVENT_DETAILS_HEADER, SLOT_EVENT_DETAILS_CONTENT]) {
    const slot = slotAPIFactory.getSlotAPI(slotId)
    slot.eventId = eventId
  }
}

export default createDetailsPageController
